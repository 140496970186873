import { createSlice } from '@reduxjs/toolkit';
import { BELIZE_ID, CAYMAN_ID, RAK_UAE_ID, UK_ID } from 'constants';
import {
    getAgent,
    getAgentType,
    getApiGroup,
    getApiMethod,
    getApiStatus,
    getBankBranch,
    getBusinessActivities,
    getCampaignAd,
    getCompanyMemberType,
    getCompanyStatus,
    getContactFrom,
    getCountry,
    getCountryCooperative,
    getCountryHasService,
    getCurrency,
    getCustomerStatus,
    getCustomerType,
    getEntityType,
    getFeeType,
    getFileTemplate,
    getFileType,
    getGaChanel,
    getGender,
    getIndustry,
    getLimitType,
    getMailChimpTag,
    getOrderStatus,
    getPackage,
    getPackageType,
    getPermissionGroup,
    getProcessStep,
    getRole,
    getService,
    getServiceType,
    getServiceUnit,
    getSubscription,
    getSupportCasePriority,
    getSupportCaseStatus,
    getSupportCaseType,
    getSupporter,
    getSyslogType,
    getTypeMember,
    getVisaOption,
    getWebsite
} from './enumThunk';

const enumSlice = createSlice({
    name: 'enum',
    initialState: {
        apiMethodOptions: [],
        loadingApiMethodOptions: false,

        apiGroupOptions: [],
        loadingApiGroupOptions: false,

        apiStatusOptions: [],
        loadingApiStatusOptions: false,

        countryOptions: [],
        loadingCountryOptions: false,

        agentTypeOptions: [],
        loadingAgentTypeOptions: false,

        syslogTypeOptions: [],
        loadingSyslogTypeOptions: false,

        orderStatusOptions: [],
        loadingOrderStatusOptions: false,

        countryHasServiceOptions: [],
        loadingCountryHasServiceOptions: false,

        countryCooperativeOptions: [],
        loadingCountryCooperativeOptions: false,

        websiteOptions: [],
        loadingWebsiteOptions: false,

        supportCaseTypeOptions: [],
        loadingSupportCaseTypeOptions: false,

        supportCaseStatusOptions: [],
        loadingSupportCaseStatusOptions: false,

        supportCasePriorityOptions: [],
        loadingSupportCasePriorityOptions: false,

        supporterOptions: [],
        loadingSupporterOptions: false,

        permissionGroupOptions: [],
        loadingPermissionGroupOptions: false,

        limitTypeOptions: [],
        loadingLimitTypeOptions: false,

        packageTypeOptions: [],
        loadingPackageTypeOptions: false,

        entityTypeOptions: [],
        loadingEntityTypeOptions: false,

        serviceTypeOptions: [],
        loadingServiceTypeOptions: false,

        feeTypeOptions: [],
        loadingFeeTypeOptions: false,

        serviceUnitOptions: [],
        loadingServiceUnitOptions: false,

        serviceOption: {},
        loadingServiceOption: false,

        roleOptions: [],
        loadingRoleOptions: false,

        subscriptionOptions: [],
        loadingSubsciptionOptions: false,

        genderOptions: [],
        loadingGenderOptions: false,

        companyStatusOptions: [],
        loadingCompanyStatusOptions: false,

        packageOptions: [],
        loadingPackageOptions: false,

        customerStatusOptions: [],
        loadingCustomerStatusOptions: false,

        customerTypeOptions: [],
        loadingCustomerTypeOptions: false,

        contactFromOptions: [],
        loadingContactFromOptions: false,

        gaChanelOptions: [],
        loadingGaChanelOptions: false,

        campaignAdOptions: [],
        loadingCampaignAdOptions: false,

        visaOption: {},
        loadingVisaOption: false,

        industryOptions: [],
        loadingIndustryOptions: false,

        mailChimpTagOptions: [],
        loadingMailChimpTagOptions: false,

        agentOption: {},
        loadingAgentOption: false,

        processStepOptions: [],
        loadingProcessStepOptions: false,

        businessActivitiesOptions: {
            [RAK_UAE_ID]: [],
            [UK_ID]: [],
            [CAYMAN_ID]: [],
            [BELIZE_ID]: [],
            commonList: []
        },
        businessAnnualReports: [],
        loadingBusinessActivitiesOptions: false,

        fileTypeOptions: [],
        loadingFileTypeOptions: false,

        fileTemplateOptions: [],
        loadingFileTemplateOptions: false,

        bankBranchOptions: [],
        loadingBankBranchOptions: false,

        currencyOptions: [],
        loadingCurrencyOptions: false,

        companyMemberTypeOptions: [],
        loadingCompanyMemberTypeOptions: false,

        typeMemberOptions: [],
        loadingTypeMemberOptions: false
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getApiMethod.fulfilled, (state, action) => {
            state.loadingApiMethodOptions = false;
            state.apiMethodOptions = action.payload;
        });
        builder.addCase(getApiGroup.fulfilled, (state, action) => {
            state.loadingApiGroupOptions = false;
            state.apiGroupOptions = action.payload;
        });
        builder.addCase(getApiStatus.fulfilled, (state, action) => {
            state.loadingApiStatusOptions = false;
            state.apiStatusOptions = action.payload;
        });
        builder.addCase(getCountry.fulfilled, (state, action) => {
            state.loadingCountryOptions = false;
            state.countryOptions = action.payload;
        });
        builder.addCase(getCountry.pending, (state, action) => {
            state.loadingCountryOptions = true;
        });
        builder.addCase(getCountry.rejected, (state, action) => {
            state.loadingCountryOptions = false;
        });

        builder.addCase(getAgentType.fulfilled, (state, action) => {
            state.loadingAgentTypeOptions = false;
            state.agentTypeOptions = action.payload;
        });
        builder.addCase(getAgentType.pending, (state, action) => {
            state.loadingAgentTypeOptions = true;
        });
        builder.addCase(getAgentType.rejected, (state, action) => {
            state.loadingAgentTypeOptions = false;
        });

        builder.addCase(getSyslogType.fulfilled, (state, action) => {
            state.loadingSyslogTypeOptions = false;
            state.syslogTypeOptions = action.payload;
        });
        builder.addCase(getSyslogType.pending, (state, action) => {
            state.loadingSyslogTypeOptions = true;
        });
        builder.addCase(getSyslogType.rejected, (state, action) => {
            state.loadingSyslogTypeOptions = false;
        });

        builder.addCase(getOrderStatus.fulfilled, (state, action) => {
            state.loadingOrderStatusOptions = false;
            state.orderStatusOptions = action.payload;
        });
        builder.addCase(getOrderStatus.pending, (state, action) => {
            state.loadingOrderStatusOptions = true;
        });
        builder.addCase(getOrderStatus.rejected, (state, action) => {
            state.loadingOrderStatusOptions = false;
        });

        builder.addCase(getCountryHasService.fulfilled, (state, action) => {
            state.loadingCountryHasServiceOptions = false;
            state.countryHasServiceOptions = action.payload;
        });
        builder.addCase(getCountryHasService.pending, (state, action) => {
            state.loadingCountryHasServiceOptions = true;
        });
        builder.addCase(getCountryHasService.rejected, (state, action) => {
            state.loadingCountryHasServiceOptions = false;
        });

        builder.addCase(getCountryCooperative.fulfilled, (state, action) => {
            state.loadingCountryCooperativeOptions = false;
            state.countryCooperativeOptions = action.payload;
        });
        builder.addCase(getCountryCooperative.pending, (state, action) => {
            state.loadingCountryCooperativeOptions = true;
        });
        builder.addCase(getCountryCooperative.rejected, (state, action) => {
            state.loadingCountryCooperativeOptions = false;
        });

        builder.addCase(getWebsite.fulfilled, (state, action) => {
            state.loadingWebsiteOptions = false;
            state.websiteOptions = action.payload;
        });
        builder.addCase(getWebsite.pending, (state, action) => {
            state.loadingWebsiteOptions = true;
        });
        builder.addCase(getWebsite.rejected, (state, action) => {
            state.loadingWebsiteOptions = false;
        });

        builder.addCase(getSupportCaseType.fulfilled, (state, action) => {
            state.loadingSupportCaseTypeOptions = false;
            state.supportCaseTypeOptions = action.payload;
        });
        builder.addCase(getSupportCaseType.pending, (state, action) => {
            state.loadingSupportCaseTypeOptions = true;
        });
        builder.addCase(getSupportCaseType.rejected, (state, action) => {
            state.loadingSupportCaseTypeOptions = false;
        });

        builder.addCase(getSupportCaseStatus.fulfilled, (state, action) => {
            state.loadingSupportCaseStatusOptions = false;
            state.supportCaseStatusOptions = action.payload;
        });
        builder.addCase(getSupportCaseStatus.pending, (state, action) => {
            state.loadingSupportCaseStatusOptions = true;
        });
        builder.addCase(getSupportCaseStatus.rejected, (state, action) => {
            state.loadingSupportCaseStatusOptions = false;
        });

        builder.addCase(getSupportCasePriority.fulfilled, (state, action) => {
            state.loadingSupportCasePriorityOptions = false;
            state.supportCasePriorityOptions = action.payload;
        });
        builder.addCase(getSupportCasePriority.pending, (state, action) => {
            state.loadingSupportCasePriorityOptions = true;
        });
        builder.addCase(getSupportCasePriority.rejected, (state, action) => {
            state.loadingSupportCasePriorityOptions = false;
        });

        builder.addCase(getSupporter.fulfilled, (state, action) => {
            state.loadingSupporterOptions = false;
            state.supporterOptions = action.payload;
        });
        builder.addCase(getSupporter.pending, (state, action) => {
            state.loadingSupporterOptions = true;
        });
        builder.addCase(getSupporter.rejected, (state, action) => {
            state.loadingSupporterOptions = false;
        });

        builder.addCase(getPermissionGroup.fulfilled, (state, action) => {
            state.loadingPermissionGroupOptions = false;
            state.permissionGroupOptions = action.payload;
        });
        builder.addCase(getPermissionGroup.pending, (state, action) => {
            state.loadingPermissionGroupOptions = true;
        });
        builder.addCase(getPermissionGroup.rejected, (state, action) => {
            state.loadingPermissionGroupOptions = false;
        });

        builder.addCase(getLimitType.fulfilled, (state, action) => {
            state.loadingLimitTypeOptions = false;
            state.limitTypeOptions = action.payload;
        });
        builder.addCase(getLimitType.pending, (state, action) => {
            state.loadingLimitTypeOptions = true;
        });
        builder.addCase(getLimitType.rejected, (state, action) => {
            state.loadingLimitTypeOptions = false;
        });

        builder.addCase(getPackageType.fulfilled, (state, action) => {
            state.loadingPackageTypeOptions = false;
            state.packageTypeOptions = action.payload;
        });
        builder.addCase(getPackageType.pending, (state, action) => {
            state.loadingPackageTypeOptions = true;
        });
        builder.addCase(getPackageType.rejected, (state, action) => {
            state.loadingPackageTypeOptions = false;
        });

        builder.addCase(getEntityType.fulfilled, (state, action) => {
            state.loadingEntityTypeOptions = false;
            state.entityTypeOptions = action.payload;
        });
        builder.addCase(getEntityType.pending, (state, action) => {
            state.loadingEntityTypeOptions = true;
        });
        builder.addCase(getEntityType.rejected, (state, action) => {
            state.loadingEntityTypeOptions = false;
        });

        builder.addCase(getServiceType.fulfilled, (state, action) => {
            state.loadingServiceTypeOptions = false;
            state.serviceTypeOptions = action.payload;
        });
        builder.addCase(getServiceType.pending, (state, action) => {
            state.loadingServiceTypeOptions = true;
        });
        builder.addCase(getServiceType.rejected, (state, action) => {
            state.loadingServiceTypeOptions = false;
        });

        builder.addCase(getFeeType.fulfilled, (state, action) => {
            state.loadingFeeTypeOptions = false;
            state.feeTypeOptions = action.payload;
        });
        builder.addCase(getFeeType.pending, (state, action) => {
            state.loadingFeeTypeOptions = true;
        });
        builder.addCase(getFeeType.rejected, (state, action) => {
            state.loadingFeeTypeOptions = false;
        });

        builder.addCase(getServiceUnit.fulfilled, (state, action) => {
            state.loadingServiceUnitOptions = false;
            state.serviceUnitOptions = action.payload;
        });
        builder.addCase(getServiceUnit.pending, (state, action) => {
            state.loadingServiceUnitOptions = true;
        });
        builder.addCase(getServiceUnit.rejected, (state, action) => {
            state.loadingServiceUnitOptions = false;
        });

        builder.addCase(getService.fulfilled, (state, action) => {
            state.loadingServiceOption = false;
            state.serviceOption = action.payload;
        });
        builder.addCase(getService.pending, (state, action) => {
            state.loadingServiceOption = true;
        });
        builder.addCase(getService.rejected, (state, action) => {
            state.loadingServiceOption = false;
        });

        builder.addCase(getRole.fulfilled, (state, action) => {
            state.loadingRoleOptions = false;
            state.roleOptions = action.payload;
        });
        builder.addCase(getRole.pending, (state, action) => {
            state.loadingRoleOptions = true;
        });
        builder.addCase(getRole.rejected, (state, action) => {
            state.loadingRoleOptions = false;
        });

        builder.addCase(getSubscription.fulfilled, (state, action) => {
            state.loadingSubsciptionOptions = false;
            state.subscriptionOptions = action.payload;
        });
        builder.addCase(getSubscription.pending, (state, action) => {
            state.loadingSubsciptionOptions = true;
        });
        builder.addCase(getSubscription.rejected, (state, action) => {
            state.loadingSubsciptionOptions = false;
        });

        builder.addCase(getGender.fulfilled, (state, action) => {
            state.loadingGenderOptions = false;
            state.genderOptions = action.payload;
        });
        builder.addCase(getGender.pending, (state, action) => {
            state.loadingGenderOptions = true;
        });
        builder.addCase(getGender.rejected, (state, action) => {
            state.loadingGenderOptions = false;
        });

        builder.addCase(getCompanyStatus.fulfilled, (state, action) => {
            state.loadingCompanyStatusOptions = false;
            state.companyStatusOptions = action.payload;
        });
        builder.addCase(getCompanyStatus.pending, (state, action) => {
            state.loadingCompanyStatusOptions = true;
        });
        builder.addCase(getCompanyStatus.rejected, (state, action) => {
            state.loadingCompanyStatusOptions = false;
        });

        builder.addCase(getCustomerType.fulfilled, (state, action) => {
            state.loadingCustomerTypeOptions = false;
            state.customerTypeOptions = action.payload;
        });
        builder.addCase(getCustomerType.pending, (state, action) => {
            state.loadingCustomerTypeOptions = true;
        });
        builder.addCase(getCustomerType.rejected, (state, action) => {
            state.loadingCustomerTypeOptions = false;
        });

        builder.addCase(getContactFrom.fulfilled, (state, action) => {
            state.loadingContactFromOptions = false;
            state.contactFromOptions = action.payload;
        });
        builder.addCase(getContactFrom.pending, (state, action) => {
            state.loadingContactFromOptions = true;
        });
        builder.addCase(getContactFrom.rejected, (state, action) => {
            state.loadingContactFromOptions = false;
        });

        builder.addCase(getGaChanel.fulfilled, (state, action) => {
            state.loadingGaChanelOptions = false;
            state.gaChanelOptions = action.payload;
        });
        builder.addCase(getGaChanel.pending, (state, action) => {
            state.loadingGaChanelOptions = true;
        });
        builder.addCase(getGaChanel.rejected, (state, action) => {
            state.loadingGaChanelOptions = false;
        });

        builder.addCase(getCampaignAd.fulfilled, (state, action) => {
            state.loadingCampaignAdOptions = false;
            state.campaignAdOptions = action.payload;
        });
        builder.addCase(getCampaignAd.pending, (state, action) => {
            state.loadingCampaignAdOptions = true;
        });
        builder.addCase(getCampaignAd.rejected, (state, action) => {
            state.loadingCampaignAdOptions = false;
        });

        builder.addCase(getVisaOption.fulfilled, (state, action) => {
            state.loadingVisaOption = false;
            state.visaOption = action.payload;
        });
        builder.addCase(getVisaOption.pending, (state, action) => {
            state.loadingVisaOption = true;
        });
        builder.addCase(getVisaOption.rejected, (state, action) => {
            state.loadingVisaOption = false;
        });

        builder.addCase(getIndustry.fulfilled, (state, action) => {
            state.loadingIndustryOptions = false;
            state.industryOptions = action.payload;
        });
        builder.addCase(getIndustry.pending, (state, action) => {
            state.loadingIndustryOptions = true;
        });
        builder.addCase(getIndustry.rejected, (state, action) => {
            state.loadingIndustryOptions = false;
        });

        builder.addCase(getMailChimpTag.fulfilled, (state, action) => {
            state.loadingMailChimpTagOptions = false;
            state.mailChimpTagOptions = action.payload;
        });
        builder.addCase(getMailChimpTag.pending, (state, action) => {
            state.loadingMailChimpTagOptions = true;
        });
        builder.addCase(getMailChimpTag.rejected, (state, action) => {
            state.loadingMailChimpTagOptions = false;
        });

        builder.addCase(getAgent.fulfilled, (state, action) => {
            state.loadingAgentOption = false;
            state.agentOption = action.payload;
        });
        builder.addCase(getAgent.pending, (state, action) => {
            state.loadingAgentOption = true;
        });
        builder.addCase(getAgent.rejected, (state, action) => {
            state.loadingAgentOption = false;
        });

        builder.addCase(getProcessStep.fulfilled, (state, action) => {
            state.loadingProcessStepOptions = false;
            state.processStepOptions = action.payload;
        });
        builder.addCase(getProcessStep.pending, (state, action) => {
            state.loadingProcessStepOptions = true;
        });
        builder.addCase(getProcessStep.rejected, (state, action) => {
            state.loadingProcessStepOptions = false;
        });

        builder.addCase(getBusinessActivities.fulfilled, (state, action) => {
            const country_id = action.meta.arg.country_id;

            const convertBusinessActivitiesOptions = (options = []) => {
                if (options.length) {
                    return options?.map((option) => {
                        return {
                            ...option,
                            name:
                                (option.sic_code ? '[' + option.sic_code + '] ' : '') +
                                option.description
                        };
                    });
                } else return null;
            };

            if (country_id === CAYMAN_ID) {
                state.businessActivitiesOptions[country_id] =
                    convertBusinessActivitiesOptions(action.payload[0]?.BusinessActivities) ||
                    action.payload?.businessActivitiesOptions ||
                    [];

                state.businessAnnualReports =
                    convertBusinessActivitiesOptions(action.payload[1]?.BusinessActivities) ||
                    action.payload?.businessAnnualReports ||
                    [];

                return;
            }

            if (state.businessActivitiesOptions[country_id]) {
                state.businessActivitiesOptions[country_id] =
                    convertBusinessActivitiesOptions(action.payload[0].BusinessActivities) ||
                    action.payload ||
                    [];
            } else {
                state.businessActivitiesOptions.commonList =
                    convertBusinessActivitiesOptions(action.payload[0].BusinessActivities) ||
                    action.payload ||
                    [];
            }
        });
        builder.addCase(getBusinessActivities.pending, (state, action) => {
            state.loadingBusinessActivitiesOptions = true;
        });
        builder.addCase(getBusinessActivities.rejected, (state, action) => {
            state.loadingBusinessActivitiesOptions = false;
        });

        builder.addCase(getFileType.fulfilled, (state, action) => {
            state.loadingFileTypeOptions = false;
            state.fileTypeOptions = action.payload;
        });
        builder.addCase(getFileType.pending, (state, action) => {
            state.loadingFileTypeOptions = true;
        });
        builder.addCase(getFileType.rejected, (state, action) => {
            state.loadingFileTypeOptions = false;
        });

        builder.addCase(getFileTemplate.fulfilled, (state, action) => {
            state.loadingFileTemplateOptions = false;
            state.fileTemplateOptions = action.payload;
        });
        builder.addCase(getFileTemplate.pending, (state, action) => {
            state.loadingFileTemplateOptions = true;
        });
        builder.addCase(getFileTemplate.rejected, (state, action) => {
            state.loadingFileTemplateOptions = false;
        });

        builder.addCase(getBankBranch.fulfilled, (state, action) => {
            state.loadingBankBranchOptions = false;
            state.bankBranchOptions = action.payload;
        });
        builder.addCase(getBankBranch.pending, (state, action) => {
            state.loadingBankBranchOptions = true;
        });
        builder.addCase(getBankBranch.rejected, (state, action) => {
            state.loadingBankBranchOptions = false;
        });

        builder.addCase(getCurrency.fulfilled, (state, action) => {
            state.loadingCurrencyOptions = false;
            state.currencyOptions = action.payload;
        });
        builder.addCase(getCurrency.pending, (state, action) => {
            state.loadingCurrencyOptions = true;
        });
        builder.addCase(getCurrency.rejected, (state, action) => {
            state.loadingCurrencyOptions = false;
        });

        builder.addCase(getPackage.fulfilled, (state, action) => {
            state.loadingPackageOptions = false;
            state.packageOptions = action.payload;
        });
        builder.addCase(getPackage.pending, (state, action) => {
            state.loadingPackageOptions = true;
        });
        builder.addCase(getPackage.rejected, (state, action) => {
            state.loadingPackageOptions = false;
        });

        builder.addCase(getCustomerStatus.fulfilled, (state, action) => {
            state.loadingCustomerStatusOptions = false;
            state.customerStatusOptions = action.payload;
        });
        builder.addCase(getCustomerStatus.pending, (state, action) => {
            state.loadingCustomerStatusOptions = true;
        });
        builder.addCase(getCustomerStatus.rejected, (state, action) => {
            state.loadingCustomerStatusOptions = false;
        });

        builder.addCase(getCompanyMemberType.fulfilled, (state, action) => {
            state.loadingCompanyMemberTypeOptions = false;
            state.companyMemberTypeOptions = action.payload;
        });
        builder.addCase(getCompanyMemberType.pending, (state, action) => {
            state.loadingCompanyMemberTypeOptions = true;
        });
        builder.addCase(getCompanyMemberType.rejected, (state, action) => {
            state.loadingCompanyMemberTypeOptions = false;
        });

        builder.addCase(getTypeMember.fulfilled, (state, action) => {
            state.loadingTypeMemberOptions = false;
            state.typeMemberOptions = action.payload;
        });
        builder.addCase(getTypeMember.pending, (state, action) => {
            state.loadingTypeMemberOptions = true;
        });
        builder.addCase(getTypeMember.rejected, (state, action) => {
            state.loadingTypeMemberOptions = false;
        });
    }
});

const { actions, reducer } = enumSlice;

export const {} = actions;

export default reducer;
