import { createAsyncThunk } from '@reduxjs/toolkit';
import { enumApi } from 'api';
import { CAYMAN_ID, RAK_UAE_ID, UK_ID } from 'constants';

export const getApiMethod = createAsyncThunk('enum/getApiMethod', async (_, thunkAPI) => {
    try {
        const response = await enumApi.getApiMethod();
        return response.data;
    } catch (e) {
        return thunkAPI.rejectWithValue(e);
    }
});

export const getApiGroup = createAsyncThunk('enum/getApiGroup', async (_, thunkAPI) => {
    try {
        const response = await enumApi.getApiGroup();
        return response.data;
    } catch (e) {
        return thunkAPI.rejectWithValue(e);
    }
});

export const getApiStatus = createAsyncThunk('enum/getApiStatus', async (_, thunkAPI) => {
    try {
        const response = await enumApi.getApiStatus();
        return response.data;
    } catch (e) {
        return thunkAPI.rejectWithValue(e);
    }
});

export const getCountry = createAsyncThunk('enum/getCountry', async (_, thunkAPI) => {
    try {
        const response = await enumApi.getCountry();
        return response.data;
    } catch (e) {
        return thunkAPI.rejectWithValue(e);
    }
});

export const getAgentType = createAsyncThunk('enum/getAgentType', async (_, thunkAPI) => {
    try {
        const response = await enumApi.getAgentType();
        return response.data;
    } catch (e) {
        return thunkAPI.rejectWithValue(e);
    }
});

export const getSyslogType = createAsyncThunk('enum/getSyslogType', async (_, thunkAPI) => {
    try {
        const response = await enumApi.getSyslogType();
        return response.data;
    } catch (e) {
        return thunkAPI.rejectWithValue(e);
    }
});

export const getOrderStatus = createAsyncThunk('enum/getOrderStatus', async (_, thunkAPI) => {
    try {
        const response = await enumApi.getOrderStatus();
        return response.data;
    } catch (e) {
        return thunkAPI.rejectWithValue(e);
    }
});

export const getCountryHasService = createAsyncThunk(
    'enum/getCountryHasService',
    async (_, thunkAPI) => {
        try {
            const response = await enumApi.getCountryHasService();
            return response.data;
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);

export const getCountryCooperative = createAsyncThunk(
    'enum/getCountryCooperative',
    async (_, thunkAPI) => {
        try {
            const response = await enumApi.getCountryCooperative();
            return response.data;
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);

export const getWebsite = createAsyncThunk('enum/getWebsite', async (_, thunkAPI) => {
    try {
        const response = await enumApi.getWebsite();
        return response.data;
    } catch (e) {
        return thunkAPI.rejectWithValue(e);
    }
});

export const getSupportCaseType = createAsyncThunk(
    'enum/getSupportCaseType',
    async (_, thunkAPI) => {
        try {
            const response = await enumApi.getSupportCaseType();
            return response.data;
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);

export const getSupportCaseStatus = createAsyncThunk(
    'enum/getSupportCaseStatus',
    async (_, thunkAPI) => {
        try {
            const response = await enumApi.getSupportCaseStatus();
            return response.data;
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);

export const getSupportCasePriority = createAsyncThunk(
    'enum/getSupportCasePriority',
    async (_, thunkAPI) => {
        try {
            const response = await enumApi.getSupportCasePriority();
            return response.data;
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);

export const getSupporter = createAsyncThunk('enum/getSupporter', async (params, thunkAPI) => {
    try {
        const response = await enumApi.getSupporter(params);
        return response.data;
    } catch (e) {
        return thunkAPI.rejectWithValue(e);
    }
});

export const getLimitType = createAsyncThunk('enum/getLimitType', async (params, thunkAPI) => {
    try {
        const response = await enumApi.getLimitType(params);
        return response.data;
    } catch (e) {
        return thunkAPI.rejectWithValue(e);
    }
});

export const getPermissionGroup = createAsyncThunk(
    'enum/getPermissionGroup',
    async (_, thunkAPI) => {
        try {
            const response = await enumApi.getPermissionGroup();
            return response.data;
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);

export const getPackageType = createAsyncThunk('enum/getPackageType', async (_, thunkAPI) => {
    try {
        const response = await enumApi.getPackageType();
        return response.data;
    } catch (e) {
        return thunkAPI.rejectWithValue(e);
    }
});

export const getEntityType = createAsyncThunk('enum/getEntityType', async (_, thunkAPI) => {
    try {
        const response = await enumApi.getEntityType();
        return response.data;
    } catch (e) {
        return thunkAPI.rejectWithValue(e);
    }
});

export const getServiceType = createAsyncThunk('enum/getServiceType', async (_, thunkAPI) => {
    try {
        const response = await enumApi.getServiceType();
        return response.data;
    } catch (e) {
        return thunkAPI.rejectWithValue(e);
    }
});

export const getFeeType = createAsyncThunk('enum/getFeeType', async (_, thunkAPI) => {
    try {
        const response = await enumApi.getFeeType();
        return response.data;
    } catch (e) {
        return thunkAPI.rejectWithValue(e);
    }
});

export const getServiceUnit = createAsyncThunk('enum/getServiceUnit', async (_, thunkAPI) => {
    try {
        const response = await enumApi.getServiceUnit();
        return response.data;
    } catch (e) {
        return thunkAPI.rejectWithValue(e);
    }
});

export const getService = createAsyncThunk('enum/getService', async (params, thunkAPI) => {
    try {
        const response = await enumApi.getService(params);
        return response.data;
    } catch (e) {
        return thunkAPI.rejectWithValue(e);
    }
});

export const getRole = createAsyncThunk('enum/getRole', async (_, thunkAPI) => {
    try {
        const response = await enumApi.getRole();
        return response.data;
    } catch (e) {
        return thunkAPI.rejectWithValue(e);
    }
});

export const getSubscription = createAsyncThunk('enum/getSubscription', async (_, thunkAPI) => {
    try {
        const response = await enumApi.getSubscription();
        return response.data;
    } catch (e) {
        return thunkAPI.rejectWithValue(e);
    }
});

export const getGender = createAsyncThunk('enum/getGender', async (_, thunkAPI) => {
    try {
        const response = await enumApi.getGender();
        return response.data;
    } catch (e) {
        return thunkAPI.rejectWithValue(e);
    }
});

export const getCompanyStatus = createAsyncThunk('enum/getCompanyStatus', async (_, thunkAPI) => {
    try {
        const response = await enumApi.getCompanyStatus();
        return response.data;
    } catch (e) {
        return thunkAPI.rejectWithValue(e);
    }
});

export const getPackage = createAsyncThunk('enum/getPackage', async (_, thunkAPI) => {
    try {
        const response = await enumApi.getPackage();
        return response.data;
    } catch (e) {
        return thunkAPI.rejectWithValue(e);
    }
});

export const getCustomerStatus = createAsyncThunk('enum/getCustomerStatus', async (_, thunkAPI) => {
    try {
        const response = await enumApi.getCustomerStatus();
        return response.data;
    } catch (e) {
        return thunkAPI.rejectWithValue(e);
    }
});

export const getCustomerType = createAsyncThunk('enum/getCustomerType', async (_, thunkAPI) => {
    try {
        const response = await enumApi.getCustomerType();
        return response.data;
    } catch (e) {
        return thunkAPI.rejectWithValue(e);
    }
});

export const getContactFrom = createAsyncThunk('enum/getContactFrom', async (_, thunkAPI) => {
    try {
        const response = await enumApi.getContactFrom();
        return response.data;
    } catch (e) {
        return thunkAPI.rejectWithValue(e);
    }
});

export const getGaChanel = createAsyncThunk('enum/getGaChanel', async (_, thunkAPI) => {
    try {
        const response = await enumApi.getGaChanel();
        return response.data;
    } catch (e) {
        return thunkAPI.rejectWithValue(e);
    }
});

export const getCampaignAd = createAsyncThunk('enum/getCampaignAd', async (_, thunkAPI) => {
    try {
        const response = await enumApi.getCampaignAd();
        return response.data;
    } catch (e) {
        return thunkAPI.rejectWithValue(e);
    }
});

export const getVisaOption = createAsyncThunk('enum/getVisaOption', async (_, thunkAPI) => {
    try {
        const response = await enumApi.getVisaOption();
        return response.data;
    } catch (e) {
        return thunkAPI.rejectWithValue(e);
    }
});

export const getIndustry = createAsyncThunk('enum/getIndustry', async (_, thunkAPI) => {
    try {
        const response = await enumApi.getIndustry();
        return response.data;
    } catch (e) {
        return thunkAPI.rejectWithValue(e);
    }
});

export const getMailChimpTag = createAsyncThunk('enum/getMailChimpTag', async (_, thunkAPI) => {
    try {
        const response = await enumApi.getMailChimpTag();
        return response.data;
    } catch (e) {
        return thunkAPI.rejectWithValue(e);
    }
});

export const getAgent = createAsyncThunk('enum/getAgent', async (params, thunkAPI) => {
    try {
        const response = await enumApi.getAgent(params);
        return response.data;
    } catch (e) {
        return thunkAPI.rejectWithValue(e);
    }
});

export const getProcessStep = createAsyncThunk('enum/getProcessStep', async (_, thunkAPI) => {
    try {
        const response = await enumApi.getProcessStep();
        return response.data;
    } catch (e) {
        return thunkAPI.rejectWithValue(e);
    }
});

export const getBusinessActivities = createAsyncThunk(
    'enum/getBusinessActivities',
    async (params, thunkAPI) => {
        try {
            const state = thunkAPI.getState();
            const businessActivitiesOptions = state?.enum?.businessActivitiesOptions;
            const businessAnnualReports = state?.enum?.businessAnnualReports;

            if ([RAK_UAE_ID, CAYMAN_ID, UK_ID].includes(params.country_id)) {
                if (businessActivitiesOptions[params.country_id]?.length) {
                    if (params.country_id === CAYMAN_ID) {
                        return {
                            businessActivitiesOptions: businessActivitiesOptions[params.country_id],
                            businessAnnualReports
                        };
                    }

                    return businessActivitiesOptions[params.country_id];
                }
            } else if (businessActivitiesOptions?.commonList?.length) {
                return businessActivitiesOptions?.commonList;
            }

            const response = await enumApi.getBusinessActivities(params);
            return response.data;
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);

export const getFileType = createAsyncThunk('enum/getFileType', async (params, thunkAPI) => {
    try {
        const response = await enumApi.getFileType(params);
        return response.data;
    } catch (e) {
        return thunkAPI.rejectWithValue(e);
    }
});

export const getFileTemplate = createAsyncThunk(
    'enum/getFileTemplate',
    async (params, thunkAPI) => {
        try {
            const response = await enumApi.getFileTemplate(params);
            return response.data;
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
);

export const getBankBranch = createAsyncThunk('enum/getBankBranch', async (params, thunkAPI) => {
    try {
        const response = await enumApi.getBankBranch(params);
        return response.data;
    } catch (e) {
        return thunkAPI.rejectWithValue(e);
    }
});

export const getCurrency = createAsyncThunk('enum/getCurrency', async (params, thunkAPI) => {
    try {
        const response = await enumApi.getCurrency(params);
        return response.data;
    } catch (e) {
        return thunkAPI.rejectWithValue(e);
    }
});

export const getCompanyMemberType = createAsyncThunk('enum/getCompanyMemberType', async (params, thunkAPI) => {
    try {
        const response = await enumApi.getCompanyMemberType(params);
        return response.data;
    } catch (e) {
        return thunkAPI.rejectWithValue(e);
    }
});

export const getTypeMember = createAsyncThunk('enum/getTypeMember', async (params, thunkAPI) => {
    try {
        const response = await enumApi.getTypeMember(params);
        return response.data;
    } catch (e) {
        return thunkAPI.rejectWithValue(e);
    }
});
